@import "https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500&family=Roboto:wght@400;500&display=swap";

%nunito {
  font-family: Nunito, sans-serif;
}

%roboto {
  font-family: Roboto, sans-serif;
}

h1 {
  @extend %nunito;
  line-height: 3.5rem;
  font-weight: $font-weight-normal;
}

h2 {
  @extend %nunito;
  line-height: 2.625rem;
  font-weight: $font-weight-normal;
}

h3 {
  @extend %roboto;
  line-height: 1.875rem;
  font-weight: $font-weight-medium;
}

h4 {
  @extend %roboto;
  line-height: 1.375rem;
  font-weight: $font-weight-medium;
}

h5 {
  @extend %roboto;
  line-height: 1.25rem;
  font-weight: $font-weight-medium;
}

h6 {
  @extend h5;
}

.text-body-m {
  @extend %roboto;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $font-weight-normal;
}

.text-body-s {
  @extend %roboto;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: $font-weight-medium;
}

.text-body-xs {
  @extend %roboto;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: $font-weight-normal;
}

.text-legend-m {
  @extend %roboto;
  font-size: .75rem;
  line-height: 1rem;
  font-weight: $font-weight-normal;
}

.text-legend-s {
  @extend %roboto;
  font-size: .625rem;
  line-height: .875rem;
  font-weight: $font-weight-medium;
}
