.login-page {
  height: 100%;
  background-color: $grey-light;
  h2 {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.625rem;
    color: $primary-color;
    margin-bottom: 1.5rem;
  }

  .container {
    max-width: 100%;
    padding: 0;
    display: flex;
  }

  .form-container {
    width: 33%;
    padding: 3rem;

    .logo {
      img {
        width: 6.5rem;
        margin-bottom: 3rem;
      }
    }
  }

  form {
    div:first-child {
      margin-bottom: 2.5rem;
    }
    label {
      margin-bottom: .5rem;
    }
    .forget-link {
      margin: 2rem 0 2.5rem;
    }

  }

  .media-container {
    width: 67%;
    background-repeat: no-repeat;
    background-size: cover;
  }

}
