.nav-tabs {
  .nav-link {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    color: $primary-grey;
    text-transform: uppercase;
    padding: .5rem 0;
    margin-right: 1.5rem;

    &:hover,
    &:focus {
      border-bottom: 3px $primary-color solid;
      transition: border-color .5s;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom: 3px $primary-color solid;
  }
}

.side-nav {
  @extend .list-unstyled;
  background-color: $base;

  ul {
    @extend .list-unstyled;
    background-color: $base;
  }

  .nav-link {
    padding: 1rem;
    font-weight: $font-weight-medium;
  }

  li {
    @extend .d-grid;
    display: inline-block;

    .nav-link {
      text-transform: capitalize;

      .material-icons,
      [class*="material-icons-"] {
        font-size: 1.25rem;
      }
    }
  }

  .btn-toggle {
    @extend .shadow-none;
    @extend .rounded-0;
    @extend .text-start;

    text-transform: capitalize;
    padding: 1rem;

    &:hover {
      @extend .text-primary;
    }

    &::after {
      float: right;
      content: "\e5cf";
      font-family: "Material Icons", serif;
      transition: transform .35s ease;
      transform-origin: center;
      transform: rotate(0deg);
      font-size: 1rem;
    }

    &[aria-expanded="true"]::after {
      transform: rotate(-180deg);
    }

    &[aria-expanded="true"] {
      color: $primary-grey;
      background-color: $base;
    }
  }

  .btn-toggle-nav {
    .btn-toggle {
      padding-left: 1.75rem;
    }

    .nav-link {
      font-weight: $font-weight-normal;
      padding-left: 2rem;
    }

    .nav-link:active,
    .nav-link:focus {
      padding-left: 1.75rem;
    }
  }

  .nav-link:hover,
  .nav-link:active,
  .nav-link:focus {
    color: $primary-color;
    background-color: $base;
    font-weight: $font-weight-bold;
    border-left: .25rem solid $primary-color;
    padding-left: 1.75rem;
  }

  .material-icons {
    @extend .align-middle;
    @extend .me-3;
  }
}

.side-nav.offcanvas {
  top: unset;
  bottom: unset;
  @extend .h-100;
}

.nav-pills {
  .nav-item {
    &:first-of-type .nav-link {
      border-left: $pills-button-border-width solid $primary-color;
      border-top-left-radius: $pills-button-border-radius;
      border-bottom-left-radius: $pills-button-border-radius;
    }

    &:last-of-type .nav-link {
      border-right: $pills-button-border-width solid $primary-color;
      border-top-right-radius: $pills-button-border-radius;
      border-bottom-right-radius: $pills-button-border-radius;
    }

    .nav-link {
      font-size: $pills-text-font-size;
      font-weight: $font-weight-normal;
      line-height: $pills-text-line-height;
      color: $primary-color;
      background-color: $base;
      border-left: $pills-button-border-width solid $primary-color;
      border-top: $pills-button-border-width solid $primary-color;
      border-bottom: $pills-button-border-width solid $primary-color;
      border-radius: 0;

      &.active {
        font-weight: $font-weight-bold;
        color: $base;
        background-color: $primary-color;
      }
    }

    &:not(.disabled):hover {
      .nav-link:not(.active) {
        font-weight: $font-weight-bold;
        color: $base;
        background-color: $primary-color;
      }
    }
  }

  .disabled {
    .nav-link {
      color: $grey-disabled;
      border-top: $pills-button-border-width solid $grey-disabled;
      border-bottom: $pills-button-border-width solid $grey-disabled;
      background-color: $bg-grey-disabled;
      cursor: not-allowed;
    }

    &:first-of-type .nav-link {
      border-left: $pills-button-border-width solid $grey-disabled;
    }

    &:last-of-type .nav-link {
      border-right: $pills-button-border-width solid $grey-disabled;
    }

    + .disabled .nav-link {
      border-left: $pills-button-border-width solid $grey-disabled;
    }
  }
}
