@each $color, $value in $link-colors {
  .link-#{$color} {
    color: $value;
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $value;
      text-decoration: underline;
    }

    &.disabled {
      color: $grey-disabled;
      pointer-events: none;
      &.arrow::before {
        color: $grey-disabled;
      }
    }

    &.arrow {
      line-height: 16px;
    }

    &.arrow::before {
      color: $value;
      content: "\e5df";
      font-size: 2rem;
      font-family: "Material Icons Round", serif;
      vertical-align: text-top;
      display: inline-block;
      margin-right: 0;
    }
  }
}

a.active {
  @extend .link-primary;
}
