$theme-colors: (
  "primary":    $primary-color,
  "secondary":  $flat-blue,
  "tertiary":   $primary-grey,
  "success":    $green-success,
  "warning":    $flat-yellow,
  "danger":     $red-danger,
  "light":      $grey-light,
);

$timeline-colors: (
  "primary":    $primary-color,
  "secondary":  $flat-blue,
  "tertiary":   $primary-grey,
  "success":    $green-success,
  "danger":     $red-danger,
  "dark":       $primary-grey,
  "warning":    $flat-yellow
);

$link-colors: (
  "primary":    $primary-color,
  "secondary":  $blue-link,
);

$alert-background-colors: (
  "info": $flat-light-blue,
  "success": $flat-green,
  "warning": $flat-yellow,
  "danger": $light-red-alert
);

$background-colors: (
  "dark": $grey-dark,
  "medium": $grey-medium,
  "light": $grey-light,
  "content": $base
);

$badge-colors: (
  "primary": $primary-color,
  "secondary": $flat-blue,
  "tertiary": $primary-grey,
  "success": $green-success,
  "warning": $flat-yellow,
  "danger": $red-danger,
  "light": $grey-light,
);

$data-colors: (
  "medium-grey": $grey-medium,
  "axis": $axis,
  "purple": $purple,
  "lin-purple": $lin-purple,
  "pink": $pink,
  "ocean-blue": $ocean-blue,
  "deep-blue": $deep-blue,
  "sky-blue": $sky-blue,
  "mint": $mint,
  "grass-green": $grass-green,
  "deep-green": $deep-green,
  "gold": $gold,
  "pimp-orange": $pimp-orange,
  "orange": $orange,
  "data-red": $data-red
);
