// All button
.btn {
  font-weight: $font-weight-medium;

  &:not(.btn-circle) [class^="material-icons"],
  .spinner-border.spinner-border-sm {
    @extend .me-2;
  }

  .spinner-border.spinner-border-sm {
    @extend .align-text-top;
  }
}

// Primary button
.btn-primary {
  @include button-variant(
    $background: $primary-color,
    $border: $primary-color,
    $hover-background: $base,
    $hover-border: $primary-color,
    $hover-color: $primary-color,
    $active-background: $base,
    $active-border: $primary-color,
    $active-color: $primary-color,
    $disabled-background: $bg-grey-disabled,
    $disabled-border: $bg-grey-disabled,
    $disabled-color: $grey-disabled
  );
}

// Secondary button
.btn-secondary {
  @include button-variant(
    $background: $base,
    $border: $primary-color,
    $color: $primary-color,
    $hover-background: $primary-color,
    $hover-border: $primary-color,
    $hover-color: $base,
    $active-background: $primary-color,
    $active-border: $primary-color,
    $active-color: $base,
    $disabled-background: $base,
    $disabled-border: $bg-grey-disabled,
    $disabled-color: $grey-disabled
  );
}

// Tertiary button
.btn-tertiary {
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $color: $primary-color,
    $hover-background: transparent,
    $hover-border: transparent,
    $hover-color: $primary-color,
    $active-background: transparent,
    $active-border: transparent,
    $active-color: $primary-color,
    $disabled-background: transparent,
    $disabled-border: transparent,
    $disabled-color: $grey-disabled
  );

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include font-size(.875rem);
  }

  .btn-outline-#{$color} {
    @extend .border-0;
  }
}

// Icon button
.btn [class^="material-icons"] {
  position: relative;
  vertical-align: top;
  font-size: 1.25rem;
}

.btn-circle {
  @extend .rounded-circle;
  @extend .p-2;
}

.btn-circle.btn-tertiary:hover,
.btn-circle.btn-tertiary:focus {
  color: $base;
  background-color: $primary-color;
}
