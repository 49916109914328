@import 'material-icons/iconfont/material-icons.css';

:root {
  // width: 320px; // to use it for mobile dev
  min-width: 320px;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  background: var(--tds-color-background-page) !important;
  color: var(--tds-color-foreground-primary) !important;
}
