.table {
  thead {
    @extend .align-middle;
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: 1rem;

    th {
      font-weight: $font-weight-medium;
    }

    .sub-header {
      color: $grey-dark;
      font-size: $font-size-small;
      font-weight: $font-weight-normal;
    }

    label {
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      line-height: 1rem;
    }
  }

  tbody {
    @extend .align-middle;
    font-family: $font-family-sans-serif;
    font-size: $font-size-small;

    input:not([type="checkbox"]),
    label {
      font-size: $font-size-small;
    }
  }
}
