.input-group {
  .input-group-text {
    border: none;
    background-color: transparent;
    padding: 0 1rem 0 0;

    label {
      @extend .text-start;
      margin-left: .5rem;
    }
  }

  // Override Bootstrap border-radius for input-group input text
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: $input-border-radius;
  }
}
