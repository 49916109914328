.navbar {

  @extend .p-0;
  background-color: $base;

  .navbar-brand {
    @extend .p-0;
  }

  .navbar-app-name {
    @extend .text-uppercase, .text-primary;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .navbar-nav {
    .nav-link {
      font-size: $font-size-base * .75;
    }

    .nav-item {
      .dropdown-toggle,
      .navbar-text {
        @extend .align-text-top;
      }

      .dropdown-toggle {
        font-weight: 400;
      }
    }
  }

  .separator {
    border: 2px solid $grey-light;
  }
}

.navbar.sticky-top {
  z-index: $zindex-modal;
}

.navbar .header-with-burger {

  img {
    @extend .d-inline-block;
    @extend .align-text-top;

    height: 44px;

    @include media-breakpoint-up(md) {
      height: 50px;

      margin-top: 8px;
      margin-right: 24px;
      margin-bottom: 8px;
      margin-left: 8px;
    }
  }
}


.navbar .header-with-tabs-for-desktop {

  img {
    @extend .d-inline-block;
    @extend .align-text-top;

    // following are for desktop size, should be updated for mobile/tablet:
    height: 88px;

    margin-top: 12px;
    margin-right: 46px;
    margin-bottom: 12px;
    margin-left: 34px;
  }
}
