// Display
$display-font-sizes: (
  1: 4.5rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.5rem,
  6: 2rem
);

// font style
$font-size-base: .875rem;
$font-size-small: .75rem;
$h1-font-size: 2.5rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1rem;
$h6-font-size: .875rem;

$font-style-normal: normal;
$font-weight-normal: normal;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// font family roboto
$font-family-sans-serif: "Roboto";

// Body
$body-color: $primary-grey;
$body-bg: $grey-light;

// text
$text-muted: $grey-disabled;

// Button link
$btn-link-color: $primary-color;
$btn-link-hover-color: $primary-color;
$btn-link-disabled-color: $grey-disabled;
$link-hover-decoration: underline;
$link-decoration: none;

// Button
$btn-border-radius-lg: 5rem;
$btn-border-radius-sm: 5rem;
$btn-border-radius: 5rem;

// Nav
$nav-tabs-border-width: 0;
$nav-tabs-link-hover-border-color: transparent;

// Navbar
$navbar-light-color: $primary-grey;

// Nav Link
$nav-link-color: $primary-grey;
$nav-link-hover-color: $primary-color;

// Tab
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-color: $primary-grey;
$nav-tabs-link-active-border-color: none;

// Alert
$alert-font-color: #374649;
$alert-border-radius: .25rem;
$alert-link-font-weight: 600;

// Toast
$toast-background-color: $toast-background;
$toast-border-radius: .5rem;
$toast-font-size: 1rem;
$toast-color: #fff;

// Forms
// > input
$input-color: $primary-grey;
$input-bg: $base;

$component-active-bg: $primary-color;

$input-border-width: .063rem;
$input-border-color: $grey-medium;
$input-border-radius: .5rem;

$input-focus-border-color: $primary-grey;
$input-focus-box-shadow: none;

$input-disabled-bg: $base;
$input-disabled-border-color: $grey-medium;

$form-label-font-size: .75rem;
$form-label-font-weight: 500;
$form-label-color: $primary-grey;

$form-text-font-size: .75rem;
$form-text-color: $primary-grey;

$input-plaintext-color: $primary-grey;

// > check
$form-switch-color: $base;
$form-switch-focus-color: $base;

// > select
$form-select-color: $primary-grey;
$form-select-indicator-color: $primary-color;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 123.959 123.958' style='enable-background:new 0 0 123.959 123.958;'><path fill='#{$form-select-indicator-color}' d='M117.979,28.017h-112c-5.3,0-8,6.4-4.2,10.2l56,56c2.3,2.3,6.1,2.3,8.401,0l56-56 C125.979,34.417,123.279,28.017,117.979,28.017z'/></svg>");

$form-select-disabled-color: $grey-disabled;
$form-select-disabled-bg: $grey-light;
$form-select-disabled-border-color: $grey-medium;

$form-select-border-radius: .5rem;
$form-select-border-color: $grey-medium;

$form-select-focus-border-color: $primary-grey;
$form-select-focus-box-shadow: none;

// Tooltip
$tooltip-bg: $primary-grey;

// Card
$card-border-radius: 1rem;
$card-border-color: $base;
$card-bg: $base;

// Modal
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 1.125rem;
$modal-title-line-height: 1.875rem;

// Pills
$pills-text-font-size: .875rem;
$pills-text-line-height: 1.125rem;
$pills-button-border-radius: .5rem;
$pills-button-border-width: 1px;

// Accordion
$accordion-border-radius: 1rem;
$accordion-button-color: $primary-grey;
$accordion-button-bg: $base;
$accordion-button-active-bg: $base;
$accordion-button-active-color: $primary-grey;
$accordion-button-focus-box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
$accordion-icon-color: $primary-color;
$accordion-icon-active-color: $primary-color;
$accordion-border-color: $grey-medium;

// Progress bar
$progress-border-radius: 1.5rem;
$progress-bg: $flat-red;
$progress-bar-bg: $primary-color;

// Pagination
$pagination-border-color: transparent;
$pagination-bg: none;
$pagination-color: $primary-grey;
$pagination-focus-color: $primary-grey;
$pagination-focus-bg: $grey-light;
$pagination-hover-color: $primary-grey;
$pagination-hover-bg: $base;
$pagination-hover-border-color: $primary-color;
$pagination-transition: none;
$pagination-margin-start: .25rem;

// Tables
$table-group-separator-color: none;
