// Alert
.alert {
  font-size: 1rem;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  line-height: 1.5rem;
  color: $alert-font-color;
}

@each $color, $value in $alert-background-colors {
  .alert-#{$color} {
    background-color: $value;
    border-color: $value;
  }
  .alert-#{$color} .alert-link {
    color: $alert-font-color;
  }
}

// Alert Header
.alert-heading {
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
  line-height: 1.375rem;
}

// Alert Close Button
.alert .btn-close {
  width: .625rem;
  height: .625rem;
  background: transparent url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='1' fill='%23374649'  stroke='%23374649' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg>");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
}

// Alert Material Icon
.alert .material-icons-outlined {
  margin-right: 1.125rem;
}
.alert .material-icons {
  margin-right: 1.125rem;
}
