.offscreen {
  position: absolute;
  left: -99999rem;
}

.text-disabled {
  color: $grey-disabled;
}

.text-contrast {
  color: $base;
}

@each $color, $value in $background-colors {
  .bg-#{$color} {
    background-color: $value;
  }
}

@each $color, $value in $data-colors {
  .data-#{$color} {
    background-color: $value;
  }
}
