@mixin initials($size) {
  width: $size;
  height: $size;
  background-color: $flat-blue;
  text-align: center;
  border-radius: 50%;
  color: $base;
  padding: 8px;
}

.initials { @include initials(50px); }
.initials-sm { @include initials(30px); }
.initials-lg { @include initials(100px); }
