.badge {
  font-style: $font-family-sans-serif;
  font-size: .75rem;
  font-weight: $font-weight-normal;
}

// badge material-icons
.badge .material-icons {
  font-size: .75rem;
  color: $base;
  vertical-align: top;
}

@each $color, $value in $badge-colors {
  .badge-#{$color} {
    background-color: $value;
  }
}

// badge M size
.badge-m {
  padding: .5rem;
}
