// Tooltip
.tooltip {
  line-height: 1.125rem;
}
// Tooltip material-icons
.tooltip-inner .material-icons {
  font-size: .75rem;
  color: $flat-green;
  vertical-align: middle;
}
