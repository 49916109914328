.footer-group {
  @extend .pt-5, .px-3, .px-md-4, .border-top;
  background-color: $base;

  .footer-menu  {
    h4 {
      @extend .text-primary, .fw-bold;
      font-size: 1.125rem;
    }

    ul{
      @extend .p-2;
    }

    .footer-sub-menu {
      @extend .mb-1;
      list-style-type: none;

      a {
        @extend .link-tertiary, .arrow;
        font-size: .875rem;
      }
    }
  }

  .footer-logo {
    @extend .d-flex, .justify-content-between, .align-items-center;
    img {
      height: 54px;
    }
  }

  .footer-trademark {
    font-size: .875rem;
  }
}
