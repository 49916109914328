// For radio buttons and checkboxes
$form-radio-hover-circle-radius: 1.875rem;
$form-radio-hover-circle-width: 1.75rem;
$form-radio-hover-circle-height: 1.75rem;

// Override for radio buttons
@function get-radio-image-url($color) {
  @return url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23" + str-slice("#{$color}", 2, -1) + "'/></svg>");
}

.list-group-item {
  background-color: $base;

  .form-check-label {
    @extend .text-body;
  }
}

.list-group-item,
.input-group-text,
.form-check:not(.form-switch) {
  .form-check-input {
    border: .125rem solid $primary-grey;

    &:disabled {
      border: .125rem solid $grey-disabled;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
    }

    &:hover:not(&:disabled)::before {
      display: block;
      width: $form-radio-hover-circle-width;
      height: $form-radio-hover-circle-height;
      content: "";
      background: $primary-grey;
      border-radius: $form-radio-hover-circle-radius;
      opacity: .1;
      transform: translate(-.563rem, -.563rem);
    }

    &:hover:not(&:disabled):checked::before {
      background: $primary-color;
    }

    &:checked {
      border: .125rem solid $primary-color;

      &:disabled {
        border-color: $grey-disabled;
      }
    }

    &[type="radio"]:checked {
      background-image: get-radio-image-url($primary-color);

      &:disabled {
        background-color: $base;
        background-image: get-radio-image-url($grey-disabled);
      }
    }

    &:not([type="radio"]):checked {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M2 10l6 5l10-10'/></svg>");

      &:disabled {
        background-color: $grey-disabled;
      }

      &::before {
        background: $primary-color;
        opacity: .08;
      }
    }

    &:checked:not(&:disabled) ~ .form-check-label {
      color: $primary-color;
    }
  }

  .form-check-input[type="radio"] {
    background-color: $base;
  }

}

// For switches
// Switch
$form-switch-hover-circle-radius: 1.875rem;
$form-switch-hover-circle-width: 1.313rem;
$form-switch-hover-circle-height: 1.313rem;

// Mini Switch
$form-mini-switch-width: 1.25rem;
$form-mini-switch-height: .65rem;
$form-mini-switch-hover-circle-width: 1.75rem;
$form-mini-switch-hover-circle-height: 1.75rem;

@function get-switch-image-url($viewBox) {
  @return url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='#{$viewBox}'><circle r='3' fill='%23" + str-slice("#{$white}", 2, -1) + "'/></svg>");
}

%circle-hover {
  display: block;
  width: $form-switch-hover-circle-width;
  height: $form-switch-hover-circle-height;
  content: "";
  border-radius: $form-switch-hover-circle-radius;
}

.form-switch {
  .form-check-input {
    position: relative;
    background-color: $primary-grey;
    background-image: get-switch-image-url("-3 -3 8 6");
    border: .063rem solid $primary-grey;
    border-radius: $form-switch-hover-circle-radius;
    box-shadow: none;

    &:disabled {
      border: .063rem solid $primary-grey;
      opacity: .5;
    }

    &:focus {
      background-image: get-switch-image-url("-3 -3 8 6");
    }

    &:not(&:disabled):hover::before {
      @extend %circle-hover;

      background: $primary-grey;
      opacity: .1;
      transform: translate(-.313rem, -.25rem);
    }

    &:checked {
      background-color: $primary-color;
      background-image: get-switch-image-url("-5 -3 8 6");
      border: .063rem solid $primary-color;

      &:disabled {
        background-color: $primary-grey;
        border: .063rem solid $primary-grey;
      }

      &:not(&:disabled):hover::before {
        background: $primary-color;
        opacity: .08;
      }

      &:not(&:disabled):not(.switch-mini):hover::before {
        @extend %circle-hover;

        transform: translate(.625rem, -.25rem);
      }
    }
  }

  %mini-circle-hover {
    width: $form-mini-switch-hover-circle-width;
    height: $form-mini-switch-hover-circle-height;
    transform: translate(-.313rem, -.625rem);
  }

  .switch-mini {
    @extend .align-text-top;
    @extend .ms-0;
    @extend .m-1;
    width: $form-mini-switch-width;
    height: $form-mini-switch-height;
    float: none;

    &:not(&:disabled) {
      &:hover::before,
      &:checked:hover::before {
        @extend %mini-circle-hover;
      }
    }
  }
}

// For mini switches
.form-switch-mini {
  @extend .ps-0;
}

// Switch labels
.form-switch-labels {
  @extend .ps-0;

  .form-check-input {
    @extend .ms-1;
    @extend .m-1;
    float: none;
  }
}
