@each $name, $value in $gradient-energies {
  .gradient-#{$name} {
    color: transparent;
    background: $value;
    -webkit-text-fill-color: transparent;
    /* stylelint-disable */
    -webkit-background-clip: text;
    /* stylelint-enable */
    background-clip: text;
    text-shadow: 0 0 #0000;
  }
}

