//     > Primary
$primary-color: #ed0000;
$primary-grey: #374649;
//     > Structures and separators
$grey-dark: #6c7678;
$grey-medium: #b7cbd3;
//     > Backgrounds
$grey-light: #f7f9fa;
$base: #fff;

//     > Gradient color
$gradient-bg-energies: (
  "oil": linear-gradient(180deg, #ef6f00 5.33%, #e00 92.33%),
  "gas": linear-gradient(180deg, #009cea 5.33%, #4632ff 92.33%),
  "electricity": linear-gradient(180deg, #029beb 5.33%, #00aa61 92.33%),
  "green-energy": linear-gradient(180deg, #40a900 5.33%, #00884a 92.33%)
);

$gradient-energies: (
  "oil": linear-gradient(90deg, #ef6f00 0%, #e00 100%),
  "gas": linear-gradient(90deg, #009cea 0%, #4632ff 100%),
  "electricity": linear-gradient(90deg, #029beb 0%, #00aa61 100%),
  "green-energy": linear-gradient(90deg, #40a900 0%, #00884a 100%)
);

// Disabled
$bg-grey-disabled: #edeff0;
$grey-disabled: #8a8a8a;

// Backgrounds Flat
$flat-blue: #285aff;
$flat-light-blue: #80cdff;
$flat-turquoise: #92eaea;
$flat-mint: #72eac5;
$flat-green: #bcf453;
$flat-yellow: #ffe74f;
$flat-red: #fbcccc; // To delete

// Buttons and links
$blue-link: #0e7dbb;

// Alerts
$red-danger: #d10000;
$green-success: #40a900;
$light-red-alert: #ff9789;

// Toasts
$toast-action: #ff1f44;
$toast-background: $primary-grey;

// Data
$axis: #e4ebee;
$purple: #8722c6;
$lin-purple: #8a64bc;
$pink: #df02a1;
$ocean-blue: #084567;
$deep-blue: #4632ff;
$sky-blue: #009cea;
$mint: #00aa61;
$grass-green: #40a900;
$deep-green: #00884a;
$gold: #b19018;
$pimp-orange: #ef6f00;
$orange: #cb4d00;
$data-red: #e00;
