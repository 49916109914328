.page-item {

  &:first-child,
  &:last-child {
    @extend .d-flex;

    .page-link {
      color: $primary-color;
      padding-top: 0;
      padding-bottom: 0;
      @extend .d-flex;
      @extend .align-items-center;
    }
  }

  .page-link {
    border-radius: 4px;
  }
}

ul.pagination {
  @extend .mb-0;
}
