// Toast
.toast {
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  line-height: 1.5rem;

  .btn-close-white {
    opacity: 1;
  }

  .btn-tertiary {
    color: $toast-action;
  }

  // Exceptionnally remove focus on button click
  .btn:focus {
    outline: none;
    box-shadow: none;
  }
}
