:root, .light, .dark {
  --tds-size-spacing-000: 0px;
  --tds-size-radius-000: 0px;
  --tds-size-radius-rounded: 9999px; /* Always rounded border radius */
  --tds-size-border-thin: 1px; /* Used for thin borders */
  --tds-size-border-medium: 2px; /* Used for medium borders */
  --tds-size-border-thick: 4px; /* Used for thick borders */
  --tds-opacity-disabled: 0.4; /* Used as opacity for disabled elements */
  --tds-motion-easing-accelerate: cubic-bezier(0.3, 0, 1, 1); /* Starts slowly and speeds up */
  --tds-motion-easing-decelerate: cubic-bezier(0, 0, 0, 1); /* Starts quickly and slows down */
  --tds-motion-easing-standard: cubic-bezier(0.2, 0.0, 0, 1.0); /* Standard easing curve with a consistent acceleration */
  --tds-motion-duration-100: 0.1s; /* Short duration used for small utility-focused transitions */
  --tds-motion-duration-150: 0.15s; /* Short duration used for small utility-focused transitions */
  --tds-motion-duration-200: 0.2s; /* Short duration used for small utility-focused transitions */
  --tds-motion-duration-300: 0.3s; /* Short duration used for small utility-focused transitions */
  --tds-motion-duration-400: 0.4s; /* Short duration used for small utility-focused transitions */
  --tds-motion-duration-500: 0.5s; /* Short duration used for small utility-focused transitions */
  --tds-font-weight-400: 400; /* Regular font weight */
  --tds-font-weight-500: 500; /* Medium font weight */
  --tds-font-weight-600: 600; /* Semi-bold font weight */
  --tds-font-weight-700: 700; /* Bold font weight */
  --tds-font-family-display: 'Nunito', sans-serif; /* Used for brand displays, Nunito represents our brand typeface. It is primarily used in large headings and promotional materials */
  --tds-font-family-sans: 'Inter', sans-serif; /* Used as the primary typeface for titles and body text, Inter is known for its excellent readability and clean design, making it suitable for both large and small text elements */
  --tds-font-family-mono: 'Inconsolota', monospace; /* This monospaced typeface is used for code snippets and technical content, providing clarity and a distinct appearance for coding element */
  --tds-private-size-spacing-step: 8px;
  --tds-private-size-radius-step: 8px;
  --tds-primitive-font-size-10: 0.625rem;
  --tds-primitive-font-size-12: 0.75rem;
  --tds-primitive-font-size-14: 0.875rem;
  --tds-primitive-font-size-16: 1rem;
  --tds-primitive-font-size-18: 1.125rem;
  --tds-primitive-font-size-20: 1.25rem;
  --tds-primitive-font-size-24: 1.5rem;
  --tds-primitive-font-size-28: 1.75rem;
  --tds-primitive-font-size-32: 2rem;
  --tds-primitive-font-size-40: 2.5rem;
  --tds-primitive-font-size-48: 3rem;
  --tds-primitive-font-size-52: 3.25rem;
  --tds-primitive-font-size-64: 4rem;
  --tds-primitive-font-size-80: 5rem;
  --tds-primitive-color-neutral-135: #7c8595;
  --tds-primitive-color-neutral-201: #676f7c;
  --tds-primitive-color-neutral-302: #535964;
  --tds-primitive-color-neutral-425: #41464e;
  --tds-primitive-color-neutral-585: #2f3339;
  --tds-primitive-color-neutral-721: #222429;
  --tds-primitive-color-neutral-846: #15171a;
  --tds-primitive-color-neutral-1000: #000000;
  --tds-primitive-color-neutral-000: #ffffff;
  --tds-primitive-color-neutral-002: #f8f8f9;
  --tds-primitive-color-neutral-009: #e9ebee;
  --tds-primitive-color-neutral-018: #d9dce2;
  --tds-primitive-color-neutral-031: #c6cbd3;
  --tds-primitive-color-neutral-055: #abb3bf;
  --tds-primitive-color-neutral-087: #939dad;
  --tds-primitive-color-red-142: #f13c3c;
  --tds-primitive-color-red-201: #e00000;
  --tds-primitive-color-red-306: #b40000;
  --tds-primitive-color-red-470: #870000;
  --tds-primitive-color-red-650: #600000;
  --tds-primitive-color-red-837: #3b0000;
  --tds-primitive-color-red-008: #feeaea;
  --tds-primitive-color-red-014: #fddcdc;
  --tds-primitive-color-red-027: #fbc2c2;
  --tds-primitive-color-red-051: #f89d9d;
  --tds-primitive-color-red-088: #f57373;
  --tds-primitive-color-blue-142: #5279f7;
  --tds-primitive-color-blue-201: #3360f6;
  --tds-primitive-color-blue-306: #1f49d2;
  --tds-primitive-color-blue-470: #17359a;
  --tds-primitive-color-blue-650: #10256b;
  --tds-primitive-color-blue-837: #0b1637;
  --tds-primitive-color-blue-008: #e8eefe;
  --tds-primitive-color-blue-014: #d9e2fd;
  --tds-primitive-color-blue-027: #c2cffc;
  --tds-primitive-color-blue-051: #9db3fb;
  --tds-primitive-color-blue-088: #7997f9;
  --tds-primitive-color-green-142: #4f9200;
  --tds-primitive-color-green-201: #447c00;
  --tds-primitive-color-green-306: #366300;
  --tds-primitive-color-green-470: #284800;
  --tds-primitive-color-green-650: #1b3100;
  --tds-primitive-color-green-837: #121b06;
  --tds-primitive-color-green-008: #e2f3cf;
  --tds-primitive-color-green-014: #d1ebb2;
  --tds-primitive-color-green-027: #b2de7e;
  --tds-primitive-color-green-051: #82c92d;
  --tds-primitive-color-green-088: #5faf00;
  --tds-primitive-color-yellow-142: #948202;
  --tds-primitive-color-yellow-201: #7f6f02;
  --tds-primitive-color-yellow-306: #645802;
  --tds-primitive-color-yellow-470: #494001;
  --tds-primitive-color-yellow-650: #322c01;
  --tds-primitive-color-yellow-837: #1b1808;
  --tds-primitive-color-yellow-008: #fcf09a;
  --tds-primitive-color-yellow-014: #fae44b;
  --tds-primitive-color-yellow-027: #edcf04;
  --tds-primitive-color-yellow-051: #cfb603;
  --tds-primitive-color-yellow-088: #b29b03;
  --tds-primitive-color-orange-142: #c56a0e;
  --tds-primitive-color-orange-201: #a85b0c;
  --tds-primitive-color-orange-306: #864809;
  --tds-primitive-color-orange-470: #613507;
  --tds-primitive-color-orange-650: #432405;
  --tds-primitive-color-orange-837: #261403;
  --tds-primitive-color-orange-008: #feebd7;
  --tds-primitive-color-orange-014: #fddebe;
  --tds-primitive-color-orange-027: #fbc68f;
  --tds-primitive-color-orange-051: #f8a34c;
  --tds-primitive-color-orange-088: #eb7f10;
  --tds-primitive-color-pink-142: #dc4e7e;
  --tds-primitive-color-pink-201: #d3235f;
  --tds-primitive-color-pink-306: #a91b4b;
  --tds-primitive-color-pink-470: #7d1437;
  --tds-primitive-color-pink-650: #570e27;
  --tds-primitive-color-pink-837: #320817;
  --tds-primitive-color-pink-008: #fbe9ef;
  --tds-primitive-color-pink-014: #f8dbe5;
  --tds-primitive-color-pink-027: #f3c3d3;
  --tds-primitive-color-pink-051: #eca0ba;
  --tds-primitive-color-pink-088: #e47a9e;
  --tds-primitive-color-purple-142: #9d63ec;
  --tds-primitive-color-purple-201: #8b47e9;
  --tds-primitive-color-purple-306: #6f38b9;
  --tds-primitive-color-purple-470: #512987;
  --tds-primitive-color-purple-650: #381c5d;
  --tds-primitive-color-purple-837: #1f1035;
  --tds-primitive-color-purple-008: #f2eafd;
  --tds-primitive-color-purple-014: #eadefb;
  --tds-primitive-color-purple-027: #dcc8f8;
  --tds-primitive-color-purple-051: #c8a7f4;
  --tds-primitive-color-purple-088: #b386f1;
  --tds-primitive-color-teal-142: #16927c;
  --tds-primitive-color-teal-201: #137d6a;
  --tds-primitive-color-teal-306: #0f6455;
  --tds-primitive-color-teal-470: #0b483e;
  --tds-primitive-color-teal-650: #08312a;
  --tds-primitive-color-teal-837: #041c18;
  --tds-primitive-color-teal-008: #d5f3ee;
  --tds-primitive-color-teal-014: #beece4;
  --tds-primitive-color-teal-027: #8eded0;
  --tds-primitive-color-teal-051: #48c9b3;
  --tds-primitive-color-teal-088: #1baf95;
  --tds-primitive-color-grey-142: #7c828e;
  --tds-primitive-color-grey-201: #686f7c;
  --tds-primitive-color-grey-306: #525863;
  --tds-primitive-color-grey-470: #3c4048;
  --tds-primitive-color-grey-650: #292c31;
  --tds-primitive-color-grey-837: #17181b;
  --tds-primitive-color-grey-008: #ecedef;
  --tds-primitive-color-grey-014: #e1e3e5;
  --tds-primitive-color-grey-027: #ced0d5;
  --tds-primitive-color-grey-051: #b2b6bc;
  --tds-primitive-color-grey-088: #979ca5;
  --tds-primitive-color-white-100: rgba(255, 255, 255, 0.05);
  --tds-primitive-color-white-200: rgba(255, 255, 255, 0.1);
  --tds-primitive-color-white-300: rgba(255, 255, 255, 0.15);
  --tds-primitive-color-white-400: rgba(255, 255, 255, 0.2);
  --tds-primitive-color-white-500: rgba(255, 255, 255, 0.35);
  --tds-primitive-color-white-600: rgba(255, 255, 255, 0.45);
  --tds-primitive-color-white-700: rgba(255, 255, 255, 0.6);
  --tds-primitive-color-white-800: rgba(255, 255, 255, 0.8);
  --tds-primitive-color-white-000: rgba(255, 255, 255, 0);
  --tds-primitive-color-black-100: rgba(0, 0, 0, 0.05);
  --tds-primitive-color-black-200: rgba(0, 0, 0, 0.1);
  --tds-primitive-color-black-300: rgba(0, 0, 0, 0.15);
  --tds-primitive-color-black-400: rgba(0, 0, 0, 0.2);
  --tds-primitive-color-black-500: rgba(0, 0, 0, 0.3);
  --tds-primitive-color-black-600: rgba(0, 0, 0, 0.45);
  --tds-primitive-color-black-700: rgba(0, 0, 0, 0.6);
  --tds-primitive-color-black-800: rgba(0, 0, 0, 0.8);
  --tds-primitive-color-black-000: rgba(0, 0, 0, 0);
  --tds-component-toggle-track-size-width-default: 40px;
  --tds-component-toggle-track-size-width-small: 28px;
  --tds-component-toggle-track-size-height-default: 20px;
  --tds-component-toggle-track-size-height-small: 16px;
  --tds-component-toggle-handle-size-default: 16px;
  --tds-component-toggle-handle-size-small: 12px;
  --tds-component-icon-button-size-sm: 24px;
  --tds-component-icon-button-size-md: 32px;
  --tds-component-icon-button-size-lg: 40px;
  --tds-component-header-size-height: 64px;
  --tds-component-company-logo-size-height: 56px;
  --tds-component-company-logo-size-width: 56px;
  --tds-component-datatable-row-color-background-100-odd: #ececec;
  --tds-component-datatable-row-color-background-200-odd: #ececec;
  --tds-component-datatable-row-color-background-300-odd: #ececec;
  --tds-component-datatable-header-color-background-100: #ececec;
  --tds-component-datatable-header-color-background-200: #ececec;
  --tds-component-datatable-header-color-background-300: #ececec;
  --tds-font-size-display-1: var(--tds-primitive-font-size-64); /* Font size of font-display-1 */
  --tds-font-size-display-2: var(--tds-primitive-font-size-48); /* Font size of font-display-2 */
  --tds-font-size-heading-1: var(--tds-primitive-font-size-40); /* Font size of font-heading-1 */
  --tds-font-size-heading-2: var(--tds-primitive-font-size-32); /* Font size of font-heading-2 */
  --tds-font-size-heading-3: var(--tds-primitive-font-size-24); /* Font size of font-heading-3 */
  --tds-font-size-heading-4: var(--tds-primitive-font-size-20); /* Font size of font-heading-4 */
  --tds-font-size-heading-5: var(--tds-primitive-font-size-16); /* Font size of font-heading-5 */
  --tds-font-size-heading-6: var(--tds-primitive-font-size-14); /* Font size of font-heading-6 */
  --tds-font-size-body-1: var(--tds-primitive-font-size-16); /* Font size of font-body-1 */
  --tds-font-size-body-2: var(--tds-primitive-font-size-14); /* Font size of font-body-2 */
  --tds-font-size-code-1: var(--tds-primitive-font-size-16); /* Font size of font-code-1 */
  --tds-font-size-code-2: var(--tds-primitive-font-size-14); /* Font size of font-code-2 */
  --tds-font-size-label: var(--tds-primitive-font-size-12); /* Font size of font-label */
  --tds-font-size-helper-text: var(--tds-primitive-font-size-12); /* Font size of font-helper-text */
  --tds-font-size-caption: var(--tds-primitive-font-size-10); /* Font size of font-caption */
  --tds-font-line-height-display-1: var(--tds-primitive-font-size-80); /* Line height of font-display-1 */
  --tds-font-line-height-display-2: var(--tds-primitive-font-size-64); /* Line height of font-display-2 */
  --tds-font-line-height-heading-1: var(--tds-primitive-font-size-48); /* Line height of font-heading-1 */
  --tds-font-line-height-heading-2: var(--tds-primitive-font-size-40); /* Line height of font-heading-2 */
  --tds-font-line-height-heading-3: var(--tds-primitive-font-size-32); /* Line height of font-heading-3 */
  --tds-font-line-height-heading-4: var(--tds-primitive-font-size-28); /* Line height of font-heading-4 */
  --tds-font-line-height-heading-5: var(--tds-primitive-font-size-24); /* Line height of font-heading-5 */
  --tds-font-line-height-heading-6: var(--tds-primitive-font-size-20); /* Line height of font-heading-6 */
  --tds-font-line-height-body-1: var(--tds-primitive-font-size-24); /* Line height of font-body-1 */
  --tds-font-line-height-body-2: var(--tds-primitive-font-size-20); /* Line height of font-body-2 */
  --tds-font-line-height-code-1: var(--tds-primitive-font-size-24); /* Line height of font-code-1 */
  --tds-font-line-height-code-2: var(--tds-primitive-font-size-20); /* Line height of font-code-2 */
  --tds-font-line-height-label: var(--tds-primitive-font-size-16); /* Line height of font-label */
  --tds-font-line-height-helper-text: var(--tds-primitive-font-size-16); /* Line height of font-helper-text */
  --tds-font-line-height-caption: var(--tds-primitive-font-size-16); /* Line height of font-caption */
  --tds-font-display-1: var(--tds-font-weight-600) var(--tds-font-size-display-1)/var(--tds-font-line-height-display-1) var(--tds-font-family-display); /* Used primarily for prominent headings and major brand statements */
  --tds-font-display-2: var(--tds-font-weight-600) var(--tds-font-size-display-2)/var(--tds-font-line-height-display-2) var(--tds-font-family-display); /* Smaller variation of font-display-1 */
  --tds-font-heading-1: var(--tds-font-weight-600) var(--tds-font-size-heading-1)/var(--tds-font-line-height-heading-1) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h1 or equivalent */
  --tds-font-heading-2: var(--tds-font-weight-600) var(--tds-font-size-heading-2)/var(--tds-font-line-height-heading-2) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h2 or equivalent */
  --tds-font-heading-3: var(--tds-font-weight-600) var(--tds-font-size-heading-3)/var(--tds-font-line-height-heading-3) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h3 or equivalent */
  --tds-font-heading-4: var(--tds-font-weight-600) var(--tds-font-size-heading-4)/var(--tds-font-line-height-heading-4) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h4 or equivalent */
  --tds-font-heading-5: var(--tds-font-weight-600) var(--tds-font-size-heading-5)/var(--tds-font-line-height-heading-5) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h5 or equivalent */
  --tds-font-heading-6: var(--tds-font-weight-600) var(--tds-font-size-heading-6)/var(--tds-font-line-height-heading-6) var(--tds-font-family-sans); /* Used for section titles and important subheadings. Should be used on h6 or equivalent */
  --tds-font-body-1: var(--tds-font-weight-400) var(--tds-font-size-body-1)/var(--tds-font-line-height-body-1) var(--tds-font-family-sans); /* Used for the main content text, ensuring readability and comfort for extended reading. */
  --tds-font-body-2: var(--tds-font-weight-400) var(--tds-font-size-body-2)/var(--tds-font-line-height-body-2) var(--tds-font-family-sans); /* Smaller variation of font-body-1 */
  --tds-font-code-1: var(--tds-font-weight-400) var(--tds-font-size-code-1)/var(--tds-font-line-height-code-1) var(--tds-font-family-mono); /* Code styles are designed for presenting code snippets and technical content */
  --tds-font-code-2: var(--tds-font-weight-400) var(--tds-font-size-code-2)/var(--tds-font-line-height-code-2) var(--tds-font-family-mono); /* Smaller variation of font-code-2 */
  --tds-font-label: var(--tds-font-weight-400) var(--tds-font-size-label)/var(--tds-font-line-height-label) var(--tds-font-family-sans); /* Used for form labels */
  --tds-font-helper-text: var(--tds-font-weight-400) var(--tds-font-size-helper-text)/var(--tds-font-line-height-helper-text) var(--tds-font-family-sans); /* Used for supplementary information. It provides additional context or instructions to the user without overwhelming the primary content */
  --tds-font-caption: var(--tds-font-weight-500) var(--tds-font-size-caption)/var(--tds-font-line-height-caption) var(--tds-font-family-sans); /* Used for brief, descriptive text. Use sparingly */
  --tds-color-foreground-primary: var(--tds-primitive-color-neutral-721); /* Default foreground color */
  --tds-color-foreground-secondary: var(--tds-primitive-color-black-700); /* Secondary foreground color */
  --tds-color-foreground-inverse: var(--tds-primitive-color-neutral-000); /* Foreground color to use on inverted backgrounds */
  --tds-color-foreground-on-color: var(--tds-primitive-color-neutral-000); /* Foreground color on interactive colors or button colors (doesn't change in light/dark) */
  --tds-color-foreground-on-color-inverse: var(--tds-primitive-color-neutral-721); /* Alternative foreground color on interactive colors or button colors (doesn't change in light/dark) */
  --tds-color-foreground-selected: var(--tds-primitive-color-neutral-721); /* Foreground color on selected backgrounds */
  --tds-color-foreground-status-info: var(--tds-primitive-color-blue-306); /* Foreground color to indicate informative content */
  --tds-color-foreground-status-success: var(--tds-primitive-color-green-306); /* Foreground color to indicate successful content */
  --tds-color-foreground-status-danger: var(--tds-primitive-color-red-306); /* Foreground color to indicate dangerous content */
  --tds-color-foreground-status-warning: var(--tds-primitive-color-yellow-306); /* Foreground color to indicate warning content */
  --tds-color-foreground-accent-red: var(--tds-primitive-color-red-306); /* Accent red foreground color */
  --tds-color-foreground-accent-green: var(--tds-primitive-color-green-306); /* Accent green foreground color */
  --tds-color-foreground-accent-blue: var(--tds-primitive-color-blue-306); /* Accent blue foreground color */
  --tds-color-foreground-accent-yellow: var(--tds-primitive-color-yellow-306); /* Accent yellow foreground color */
  --tds-color-foreground-accent-grey: var(--tds-primitive-color-grey-306); /* Accent grey foreground color */
  --tds-color-foreground-accent-orange: var(--tds-primitive-color-orange-306); /* Accent orange foreground color */
  --tds-color-foreground-accent-pink: var(--tds-primitive-color-pink-306); /* Accent pink foreground color */
  --tds-color-foreground-accent-purple: var(--tds-primitive-color-purple-306); /* Accent purple foreground color */
  --tds-color-foreground-accent-teal: var(--tds-primitive-color-teal-306); /* Accent teal foreground color */
  --tds-color-border-subtle: var(--tds-primitive-color-black-300); /* Border color for product brand */
  --tds-color-border-strong: var(--tds-primitive-color-black-500); /* Medium contrast border color */
  --tds-color-border-hover: var(--tds-primitive-color-black-600); /* Hover color for border-subtle and border-strong */
  --tds-color-border-transparent: var(--tds-primitive-color-black-000); /* Transparent border color */
  --tds-color-border-focus: var(--tds-primitive-color-blue-201); /* Focus border and outline color */
  --tds-color-border-focus-on-color: var(--tds-primitive-color-blue-306); /* Focus border and outline color on colored backgrounds */
  --tds-color-border-status-success: var(--tds-primitive-color-green-088); /* Border color to indicate successful content */
  --tds-color-border-status-danger: var(--tds-primitive-color-red-088); /* Border color to indicate dangerous content */
  --tds-color-border-status-warning: var(--tds-primitive-color-yellow-088); /* Border color to indicate warning content */
  --tds-color-border-status-info: var(--tds-primitive-color-blue-088); /* Border color to indicate informative content */
  --tds-color-border-accent-red: var(--tds-primitive-color-red-088); /* Border color to indicate red accent */
  --tds-color-border-accent-green: var(--tds-primitive-color-green-088); /* Border color to indicate green accent */
  --tds-color-border-accent-blue: var(--tds-primitive-color-blue-088); /* Border color to indicate blue accent */
  --tds-color-border-accent-yellow: var(--tds-primitive-color-yellow-088); /* Border color to indicate yellow accent */
  --tds-color-border-accent-grey: var(--tds-primitive-color-grey-088); /* Border color to indicate grey accent */
  --tds-color-border-accent-orange: var(--tds-primitive-color-orange-088); /* Border color to indicate orange accent */
  --tds-color-border-accent-pink: var(--tds-primitive-color-pink-088); /* Border color to indicate pink accent */
  --tds-color-border-accent-purple: var(--tds-primitive-color-purple-088); /* Border color to indicate purple accent */
  --tds-color-border-accent-teal: var(--tds-primitive-color-teal-088); /* Border color to indicate teal accent */
  --tds-color-background-page: var(--tds-primitive-color-neutral-002); /* Used for page background */
  --tds-color-background-transparent-default: var(--tds-primitive-color-black-000); /* Used for transparent backgrounds */
  --tds-color-background-transparent-hover: var(--tds-primitive-color-black-100); /* Hover color for background-transparent-default */
  --tds-color-background-transparent-active: var(--tds-primitive-color-black-200); /* Active color for background-transparent-default */
  --tds-color-background-transparent-on-color-default: var(--tds-primitive-color-black-000); /* Used for transparent backgrounds on color */
  --tds-color-background-transparent-on-color-hover: var(--tds-primitive-color-black-300); /* Hover color for background-transparent-on-color-default */
  --tds-color-background-transparent-on-color-active: var(--tds-primitive-color-black-400); /* Active color for background-transparent-on-color-default */
  --tds-color-background-selected: var(--tds-primitive-color-black-200); /* Used for the background color of selected elements */
  --tds-color-background-status-info: var(--tds-primitive-color-blue-008); /* Background color to indicate informative content */
  --tds-color-background-status-danger: var(--tds-primitive-color-red-008); /* Background color to indicate dangerous content */
  --tds-color-background-status-success: var(--tds-primitive-color-green-008); /* Background color to indicate successful content */
  --tds-color-background-status-warning: var(--tds-primitive-color-yellow-008); /* Background color to indicate warning content */
  --tds-color-background-accent-red-subtle-default: var(--tds-primitive-color-red-008); /* Background color for accent red muted */
  --tds-color-background-accent-red-subtle-hover: var(--tds-primitive-color-red-014); /* Hover color for background-accent-red-muted */
  --tds-color-background-accent-red-subtle-active: var(--tds-primitive-color-red-027); /* Active color for background-accent-red-muted */
  --tds-color-background-accent-red-strong-default: var(--tds-primitive-color-red-201); /* Background color for accent red muted */
  --tds-color-background-accent-red-strong-hover: var(--tds-primitive-color-red-306); /* Hover color for background-accent-red-muted */
  --tds-color-background-accent-red-strong-active: var(--tds-primitive-color-red-470); /* Active color for background-accent-red-muted */
  --tds-color-background-accent-green-subtle-default: var(--tds-primitive-color-green-008); /* Background color for accent green muted */
  --tds-color-background-accent-green-subtle-hover: var(--tds-primitive-color-green-014); /* Hover color for background-accent-green-muted */
  --tds-color-background-accent-green-subtle-active: var(--tds-primitive-color-green-027); /* Active color for background-accent-green-muted */
  --tds-color-background-accent-green-strong-default: var(--tds-primitive-color-green-201); /* Background color for accent green muted */
  --tds-color-background-accent-green-strong-hover: var(--tds-primitive-color-green-306); /* Hover color for background-accent-green-muted */
  --tds-color-background-accent-green-strong-active: var(--tds-primitive-color-green-470); /* Active color for background-accent-green-muted */
  --tds-color-background-accent-blue-subtle-default: var(--tds-primitive-color-blue-008); /* Background color for accent blue muted */
  --tds-color-background-accent-blue-subtle-hover: var(--tds-primitive-color-blue-014); /* Hover color for background-accent-blue-muted */
  --tds-color-background-accent-blue-subtle-active: var(--tds-primitive-color-blue-027); /* Active color for background-accent-blue-muted */
  --tds-color-background-accent-blue-strong-default: var(--tds-primitive-color-blue-201); /* Background color for accent blue muted */
  --tds-color-background-accent-blue-strong-hover: var(--tds-primitive-color-blue-306); /* Hover color for background-accent-blue-muted */
  --tds-color-background-accent-blue-strong-active: var(--tds-primitive-color-blue-470); /* Active color for background-accent-blue-muted */
  --tds-color-background-accent-yellow-subtle-default: var(--tds-primitive-color-yellow-008); /* Background color for accent yellow muted */
  --tds-color-background-accent-yellow-subtle-hover: var(--tds-primitive-color-yellow-014); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-subtle-active: var(--tds-primitive-color-yellow-027); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-strong-default: var(--tds-primitive-color-yellow-201); /* Background color for accent yellow muted */
  --tds-color-background-accent-yellow-strong-hover: var(--tds-primitive-color-yellow-306); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-strong-active: var(--tds-primitive-color-yellow-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-grey-subtle-default: var(--tds-primitive-color-grey-008); /* Background color for accent grey muted */
  --tds-color-background-accent-grey-subtle-hover: var(--tds-primitive-color-grey-014); /* Hover color for background-accent-grey-muted */
  --tds-color-background-accent-grey-subtle-active: var(--tds-primitive-color-grey-027); /* Active color for background-accent-grey-muted */
  --tds-color-background-accent-grey-strong-default: var(--tds-primitive-color-grey-201); /* Background color for accent grey muted */
  --tds-color-background-accent-grey-strong-hover: var(--tds-primitive-color-grey-306); /* Hover color for background-accent-grey-muted */
  --tds-color-background-accent-grey-strong-active: var(--tds-primitive-color-grey-470); /* Active color for background-accent-grey-muted */
  --tds-color-background-accent-orange-subtle-default: var(--tds-primitive-color-orange-008); /* Background color for accent yellow muted */
  --tds-color-background-accent-orange-subtle-hover: var(--tds-primitive-color-orange-014); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-subtle-active: var(--tds-primitive-color-orange-027); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-strong-default: var(--tds-primitive-color-orange-201); /* Background color for accent yellow muted */
  --tds-color-background-accent-orange-strong-hover: var(--tds-primitive-color-orange-306); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-strong-active: var(--tds-primitive-color-orange-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-subtle-default: var(--tds-primitive-color-pink-008); /* Background color for accent yellow muted */
  --tds-color-background-accent-pink-subtle-hover: var(--tds-primitive-color-pink-014); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-subtle-active: var(--tds-primitive-color-pink-027); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-strong-default: var(--tds-primitive-color-pink-201); /* Background color for accent yellow muted */
  --tds-color-background-accent-pink-strong-hover: var(--tds-primitive-color-pink-306); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-strong-active: var(--tds-primitive-color-pink-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-subtle-default: var(--tds-primitive-color-purple-008); /* Background color for accent yellow muted */
  --tds-color-background-accent-purple-subtle-hover: var(--tds-primitive-color-purple-014); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-subtle-active: var(--tds-primitive-color-purple-027); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-strong-default: var(--tds-primitive-color-purple-201); /* Background color for accent yellow muted */
  --tds-color-background-accent-purple-strong-hover: var(--tds-primitive-color-purple-306); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-strong-active: var(--tds-primitive-color-purple-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-subtle-default: var(--tds-primitive-color-teal-008); /* Background color for accent yellow muted */
  --tds-color-background-accent-teal-subtle-hover: var(--tds-primitive-color-teal-014); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-subtle-active: var(--tds-primitive-color-teal-027); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-strong-default: var(--tds-primitive-color-teal-201); /* Background color for accent yellow muted */
  --tds-color-background-accent-teal-strong-hover: var(--tds-primitive-color-teal-306); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-strong-active: var(--tds-primitive-color-teal-470); /* Active color for background-accent-yellow-muted */
  --tds-private-color-layer-100-default: var(--tds-primitive-color-neutral-000);
  --tds-private-color-layer-100-hover: var(--tds-primitive-color-neutral-009);
  --tds-private-color-layer-100-active: var(--tds-primitive-color-neutral-031);
  --tds-private-color-layer-200-default: var(--tds-primitive-color-neutral-002);
  --tds-private-color-layer-200-hover: var(--tds-primitive-color-neutral-009);
  --tds-private-color-layer-200-active: var(--tds-primitive-color-neutral-031);
  --tds-private-color-field-100: var(--tds-primitive-color-neutral-000);
  --tds-private-color-field-200: var(--tds-primitive-color-neutral-002);
  --tds-private-color-field-300: var(--tds-primitive-color-neutral-000);
  --tds-private-color-segmented-control-100: var(--tds-primitive-color-neutral-009);
  --tds-private-color-segmented-control-200: var(--tds-primitive-color-neutral-009);
  --tds-private-color-segmented-control-300: var(--tds-primitive-color-neutral-009);
  --tds-private-color-brand-default: var(--tds-primitive-color-blue-201);
  --tds-private-color-brand-hover: var(--tds-primitive-color-blue-306);
  --tds-private-color-brand-active: var(--tds-primitive-color-blue-470);
  --tds-component-toggle-track-color-background-off-default: var(--tds-primitive-color-neutral-031);
  --tds-component-toggle-track-color-background-off-hover: var(--tds-primitive-color-neutral-055);
  --tds-component-toggle-track-color-background-off-active: var(--tds-primitive-color-neutral-087);
  --tds-component-toggle-handle-color-background-default: var(--tds-primitive-color-neutral-000);
  --tds-component-segmented-control-option-color-background-hover: var(--tds-primitive-color-black-200);
  --tds-component-segmented-control-option-color-background-selected: var(--tds-primitive-color-neutral-000);
  --tds-component-link-color-default: var(--tds-primitive-color-blue-201);
  --tds-component-link-color-hover: var(--tds-primitive-color-blue-306);
  --tds-component-link-color-active: var(--tds-primitive-color-blue-470);
  --tds-component-link-color-visited: var(--tds-primitive-color-purple-201);
  --tds-component-icon-button-size-radius: var(--tds-size-radius-rounded);
  --tds-component-icon-size-xs: var(--tds-primitive-font-size-16);
  --tds-component-icon-size-sm: var(--tds-primitive-font-size-20);
  --tds-component-icon-size-md: var(--tds-primitive-font-size-24);
  --tds-component-icon-size-lg: var(--tds-primitive-font-size-32);
  --tds-component-icon-size-xl: var(--tds-primitive-font-size-40);
  --tds-component-datatable-row-color-background-100-default: var(--tds-primitive-color-neutral-000);
  --tds-component-datatable-row-color-background-200-default: var(--tds-primitive-color-neutral-000);
  --tds-component-datatable-row-color-background-300-default: var(--tds-primitive-color-neutral-000);
  --tds-color-foreground-brand: var(--tds-private-color-brand-default); /* Foreground color for the product brand */
  --tds-color-border-brand: var(--tds-private-color-brand-default); /* Border color for product brand */
  --tds-color-background-brand-default: var(--tds-private-color-brand-default); /* Background color for the product brand */
  --tds-color-background-brand-hover: var(--tds-private-color-brand-hover); /* Hover color for background-brand-default */
  --tds-color-background-brand-active: var(--tds-private-color-brand-active); /* Active color for background-brand-default */
  --tds-color-background-field: var(--tds-private-color-field-100); /* Used for the background of form fields, the color can adapt to the layer they are on */
  --tds-component-segmented-control-color-background: var(--tds-private-color-segmented-control-100);
  --tds-component-layer-color-background-default: var(--tds-private-color-layer-100-default);
  --tds-component-layer-color-background-hover: var(--tds-private-color-layer-100-hover);
  --tds-component-layer-color-background-active: var(--tds-private-color-layer-100-active);
  --tds-component-icon-button-color-foreground: var(--tds-color-foreground-primary);
  --tds-component-icon-button-color-background-default: var(--tds-color-background-transparent-default);
  --tds-component-icon-button-color-background-hover: var(--tds-color-background-transparent-hover);
  --tds-component-icon-button-color-background-active: var(--tds-color-background-transparent-active);
  --tds-component-icon-button-size-padding-sm: var(--tds-size-spacing-050);
  --tds-component-icon-button-size-padding-md: var(--tds-size-spacing-075);
  --tds-component-icon-button-size-padding-lg: var(--tds-size-spacing-100);
  --tds-component-datatable-row-color-background-hover: var(--tds-color-background-transparent-hover);
  --tds-component-datatable-row-color-background-selected: var(--tds-color-background-selected);
  --tds-component-datatable-header-color-background-hover: var(--tds-color-background-transparent-hover);
  --tds-component-datatable-header-color-background-selected: var(--tds-color-background-selected);
  --tds-component-button-color-foreground-primary: var(--tds-color-foreground-inverse);
  --tds-component-button-color-background-secondary-default: var(--tds-color-background-transparent-default);
  --tds-component-button-color-background-secondary-hover: var(--tds-color-background-transparent-hover);
  --tds-component-button-color-background-secondary-active: var(--tds-color-background-transparent-active);
  --tds-component-button-color-background-tertiary-default: var(--tds-color-background-transparent-default);
  --tds-component-button-color-background-tertiary-hover: var(--tds-color-background-transparent-hover);
  --tds-component-button-color-background-tertiary-active: var(--tds-color-background-transparent-active);
  --tds-component-button-color-border-primary: var(--tds-color-border-transparent);
  --tds-component-button-color-border-tertiary: var(--tds-color-border-transparent);
  --tds-color-border-selected: var(--tds-color-border-brand); /* Selected border color */
  --tds-color-background-selected-inverse: var(--tds-color-background-brand-default); /* Used as alternative for the background color of selected elements */
  --tds-component-toggle-track-color-background-on-default: var(--tds-color-background-brand-default);
  --tds-component-toggle-track-color-background-on-hover: var(--tds-color-background-brand-hover);
  --tds-component-toggle-track-color-background-on-active: var(--tds-color-background-brand-active);
  --tds-component-button-color-foreground-secondary: var(--tds-color-foreground-brand);
  --tds-component-button-color-foreground-tertiary: var(--tds-color-foreground-brand);
  --tds-component-button-color-background-primary-default: var(--tds-color-background-brand-default);
  --tds-component-button-color-background-primary-hover: var(--tds-color-background-brand-hover);
  --tds-component-button-color-background-primary-active: var(--tds-color-background-brand-active);
  --tds-component-button-color-border-secondary: var(--tds-color-border-brand);
}

:root, .light, .dark {
  --tds-size-spacing-100: calc(1 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-125: calc(1.25 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-150: calc(1.5 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-200: calc(2 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-250: calc(2.5 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-300: calc(3 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-400: calc(4 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-500: calc(5 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-600: calc(6 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-800: calc(8 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-1000: calc(10 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-025: calc(0.25 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-050: calc(0.5 * var(--tds-private-size-spacing-step));
  --tds-size-spacing-075: calc(0.75 * var(--tds-private-size-spacing-step));
  --tds-size-radius-100: calc(1 * var(--tds-private-size-radius-step));
  --tds-size-radius-150: calc(1.5 * var(--tds-private-size-radius-step));
  --tds-size-radius-200: calc(2 * var(--tds-private-size-radius-step));
  --tds-size-radius-250: calc(2.5 * var(--tds-private-size-radius-step));
  --tds-size-radius-300: calc(3 * var(--tds-private-size-radius-step));
  --tds-size-radius-025: calc(0.25 * var(--tds-private-size-radius-step));
  --tds-size-radius-050: calc(0.5 * var(--tds-private-size-radius-step));
  --tds-size-radius-075: calc(0.75 * var(--tds-private-size-radius-step));
}

.dark {
  --tds-color-foreground-primary: var(--tds-primitive-color-neutral-000); /* Default foreground color */
  --tds-color-foreground-secondary: var(--tds-primitive-color-white-700); /* Secondary foreground color */
  --tds-color-foreground-inverse: var(--tds-primitive-color-neutral-846); /* Foreground color to use on inverted backgrounds */
  --tds-color-foreground-on-color-inverse: var(--tds-primitive-color-neutral-846); /* Alternative foreground color on interactive colors or button colors (doesn't change in light/dark) */
  --tds-color-foreground-selected: var(--tds-primitive-color-neutral-000); /* Foreground color on selected backgrounds */
  --tds-color-foreground-status-info: var(--tds-primitive-color-blue-051); /* Foreground color to indicate informative content */
  --tds-color-foreground-status-success: var(--tds-primitive-color-green-051); /* Foreground color to indicate successful content */
  --tds-color-foreground-status-danger: var(--tds-primitive-color-red-051); /* Foreground color to indicate dangerous content */
  --tds-color-foreground-status-warning: var(--tds-primitive-color-yellow-051); /* Foreground color to indicate warning content */
  --tds-color-foreground-accent-red: var(--tds-primitive-color-red-088); /* Accent red foreground color */
  --tds-color-foreground-accent-green: var(--tds-primitive-color-green-088); /* Accent green foreground color */
  --tds-color-foreground-accent-blue: var(--tds-primitive-color-blue-088); /* Accent blue foreground color */
  --tds-color-foreground-accent-yellow: var(--tds-primitive-color-yellow-088); /* Accent yellow foreground color */
  --tds-color-foreground-accent-grey: var(--tds-primitive-color-grey-088); /* Accent grey foreground color */
  --tds-color-foreground-accent-orange: var(--tds-primitive-color-orange-088); /* Accent orange foreground color */
  --tds-color-foreground-accent-pink: var(--tds-primitive-color-pink-088); /* Accent pink foreground color */
  --tds-color-foreground-accent-purple: var(--tds-primitive-color-purple-088); /* Accent purple foreground color */
  --tds-color-foreground-accent-teal: var(--tds-primitive-color-teal-088); /* Accent teal foreground color */
  --tds-color-border-subtle: var(--tds-primitive-color-white-300); /* Border color for product brand */
  --tds-color-border-strong: var(--tds-primitive-color-white-500); /* Medium contrast border color */
  --tds-color-border-hover: var(--tds-primitive-color-white-600); /* Hover color for border-subtle and border-strong */
  --tds-color-border-transparent: var(--tds-primitive-color-white-000); /* Transparent border color */
  --tds-color-border-focus: var(--tds-primitive-color-blue-051); /* Focus border and outline color */
  --tds-color-border-status-success: var(--tds-primitive-color-green-306); /* Border color to indicate successful content */
  --tds-color-border-status-danger: var(--tds-primitive-color-red-306); /* Border color to indicate dangerous content */
  --tds-color-border-status-warning: var(--tds-primitive-color-yellow-306); /* Border color to indicate warning content */
  --tds-color-border-status-info: var(--tds-primitive-color-blue-306); /* Border color to indicate informative content */
  --tds-color-border-accent-red: var(--tds-primitive-color-red-470); /* Border color to indicate red accent */
  --tds-color-border-accent-green: var(--tds-primitive-color-green-470); /* Border color to indicate green accent */
  --tds-color-border-accent-blue: var(--tds-primitive-color-blue-470); /* Border color to indicate blue accent */
  --tds-color-border-accent-yellow: var(--tds-primitive-color-yellow-470); /* Border color to indicate yellow accent */
  --tds-color-border-accent-grey: var(--tds-primitive-color-grey-470); /* Border color to indicate grey accent */
  --tds-color-border-accent-orange: var(--tds-primitive-color-orange-470); /* Border color to indicate orange accent */
  --tds-color-border-accent-pink: var(--tds-primitive-color-pink-470); /* Border color to indicate pink accent */
  --tds-color-border-accent-purple: var(--tds-primitive-color-purple-470); /* Border color to indicate purple accent */
  --tds-color-border-accent-teal: var(--tds-primitive-color-teal-470); /* Border color to indicate teal accent */
  --tds-color-background-page: var(--tds-primitive-color-neutral-846); /* Used for page background */
  --tds-color-background-transparent-default: var(--tds-primitive-color-white-000); /* Used for transparent backgrounds */
  --tds-color-background-transparent-hover: var(--tds-primitive-color-white-100); /* Hover color for background-transparent-default */
  --tds-color-background-transparent-active: var(--tds-primitive-color-white-200); /* Active color for background-transparent-default */
  --tds-color-background-selected: var(--tds-primitive-color-white-200); /* Used for the background color of selected elements */
  --tds-color-background-status-info: var(--tds-primitive-color-blue-837); /* Background color to indicate informative content */
  --tds-color-background-status-danger: var(--tds-primitive-color-red-837); /* Background color to indicate dangerous content */
  --tds-color-background-status-success: var(--tds-primitive-color-green-837); /* Background color to indicate successful content */
  --tds-color-background-status-warning: var(--tds-primitive-color-yellow-837); /* Background color to indicate warning content */
  --tds-color-background-accent-red-subtle-default: var(--tds-primitive-color-red-837); /* Background color for accent red muted */
  --tds-color-background-accent-red-subtle-hover: var(--tds-primitive-color-red-650); /* Hover color for background-accent-red-muted */
  --tds-color-background-accent-red-subtle-active: var(--tds-primitive-color-red-470); /* Active color for background-accent-red-muted */
  --tds-color-background-accent-red-strong-default: var(--tds-primitive-color-red-142); /* Background color for accent red muted */
  --tds-color-background-accent-red-strong-hover: var(--tds-primitive-color-red-201); /* Hover color for background-accent-red-muted */
  --tds-color-background-accent-red-strong-active: var(--tds-primitive-color-red-306); /* Active color for background-accent-red-muted */
  --tds-color-background-accent-green-subtle-default: var(--tds-primitive-color-green-837); /* Background color for accent green muted */
  --tds-color-background-accent-green-subtle-hover: var(--tds-primitive-color-green-650); /* Hover color for background-accent-green-muted */
  --tds-color-background-accent-green-subtle-active: var(--tds-primitive-color-green-470); /* Active color for background-accent-green-muted */
  --tds-color-background-accent-green-strong-default: var(--tds-primitive-color-green-142); /* Background color for accent green muted */
  --tds-color-background-accent-green-strong-hover: var(--tds-primitive-color-green-201); /* Hover color for background-accent-green-muted */
  --tds-color-background-accent-green-strong-active: var(--tds-primitive-color-green-306); /* Active color for background-accent-green-muted */
  --tds-color-background-accent-blue-subtle-default: var(--tds-primitive-color-blue-837); /* Background color for accent blue muted */
  --tds-color-background-accent-blue-subtle-hover: var(--tds-primitive-color-blue-650); /* Hover color for background-accent-blue-muted */
  --tds-color-background-accent-blue-subtle-active: var(--tds-primitive-color-blue-470); /* Active color for background-accent-blue-muted */
  --tds-color-background-accent-blue-strong-default: var(--tds-primitive-color-blue-142); /* Background color for accent blue muted */
  --tds-color-background-accent-blue-strong-hover: var(--tds-primitive-color-blue-201); /* Hover color for background-accent-blue-muted */
  --tds-color-background-accent-blue-strong-active: var(--tds-primitive-color-blue-306); /* Active color for background-accent-blue-muted */
  --tds-color-background-accent-yellow-subtle-default: var(--tds-primitive-color-yellow-837); /* Background color for accent yellow muted */
  --tds-color-background-accent-yellow-subtle-hover: var(--tds-primitive-color-yellow-650); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-subtle-active: var(--tds-primitive-color-yellow-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-strong-default: var(--tds-primitive-color-yellow-142); /* Background color for accent yellow muted */
  --tds-color-background-accent-yellow-strong-hover: var(--tds-primitive-color-yellow-201); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-yellow-strong-active: var(--tds-primitive-color-yellow-306); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-grey-subtle-default: var(--tds-primitive-color-grey-837); /* Background color for accent grey muted */
  --tds-color-background-accent-grey-subtle-hover: var(--tds-primitive-color-grey-650); /* Hover color for background-accent-grey-muted */
  --tds-color-background-accent-grey-subtle-active: var(--tds-primitive-color-grey-470); /* Active color for background-accent-grey-muted */
  --tds-color-background-accent-grey-strong-default: var(--tds-primitive-color-grey-142); /* Background color for accent grey muted */
  --tds-color-background-accent-grey-strong-hover: var(--tds-primitive-color-grey-201); /* Hover color for background-accent-grey-muted */
  --tds-color-background-accent-grey-strong-active: var(--tds-primitive-color-grey-306); /* Active color for background-accent-grey-muted */
  --tds-color-background-accent-orange-subtle-default: var(--tds-primitive-color-orange-837); /* Background color for accent yellow muted */
  --tds-color-background-accent-orange-subtle-hover: var(--tds-primitive-color-orange-650); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-subtle-active: var(--tds-primitive-color-orange-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-strong-default: var(--tds-primitive-color-orange-142); /* Background color for accent yellow muted */
  --tds-color-background-accent-orange-strong-hover: var(--tds-primitive-color-orange-201); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-orange-strong-active: var(--tds-primitive-color-orange-306); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-subtle-default: var(--tds-primitive-color-pink-837); /* Background color for accent yellow muted */
  --tds-color-background-accent-pink-subtle-hover: var(--tds-primitive-color-pink-650); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-subtle-active: var(--tds-primitive-color-pink-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-strong-default: var(--tds-primitive-color-pink-142); /* Background color for accent yellow muted */
  --tds-color-background-accent-pink-strong-hover: var(--tds-primitive-color-pink-201); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-pink-strong-active: var(--tds-primitive-color-pink-306); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-subtle-default: var(--tds-primitive-color-purple-837); /* Background color for accent yellow muted */
  --tds-color-background-accent-purple-subtle-hover: var(--tds-primitive-color-purple-650); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-subtle-active: var(--tds-primitive-color-purple-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-strong-default: var(--tds-primitive-color-purple-142); /* Background color for accent yellow muted */
  --tds-color-background-accent-purple-strong-hover: var(--tds-primitive-color-purple-201); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-purple-strong-active: var(--tds-primitive-color-purple-306); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-subtle-default: var(--tds-primitive-color-teal-837); /* Background color for accent yellow muted */
  --tds-color-background-accent-teal-subtle-hover: var(--tds-primitive-color-teal-650); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-subtle-active: var(--tds-primitive-color-teal-470); /* Active color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-strong-default: var(--tds-primitive-color-teal-142); /* Background color for accent yellow muted */
  --tds-color-background-accent-teal-strong-hover: var(--tds-primitive-color-teal-201); /* Hover color for background-accent-yellow-muted */
  --tds-color-background-accent-teal-strong-active: var(--tds-primitive-color-teal-306); /* Active color for background-accent-yellow-muted */
  --tds-private-color-layer-100-default: var(--tds-primitive-color-neutral-721);
  --tds-private-color-layer-100-hover: var(--tds-primitive-color-neutral-425);
  --tds-private-color-layer-100-active: var(--tds-primitive-color-neutral-135);
  --tds-private-color-layer-200-default: var(--tds-primitive-color-neutral-585);
  --tds-private-color-layer-200-hover: var(--tds-primitive-color-neutral-425);
  --tds-private-color-layer-200-active: var(--tds-primitive-color-neutral-135);
  --tds-private-color-field-100: var(--tds-primitive-color-neutral-721);
  --tds-private-color-field-200: var(--tds-primitive-color-neutral-585);
  --tds-private-color-field-300: var(--tds-primitive-color-neutral-425);
  --tds-private-color-segmented-control-100: var(--tds-primitive-color-neutral-721);
  --tds-private-color-segmented-control-200: var(--tds-primitive-color-neutral-585);
  --tds-private-color-segmented-control-300: var(--tds-primitive-color-neutral-425);
  --tds-private-color-brand-default: var(--tds-primitive-color-blue-088);
  --tds-private-color-brand-hover: var(--tds-primitive-color-blue-051);
  --tds-private-color-brand-active: var(--tds-primitive-color-blue-027);
  --tds-component-toggle-track-color-background-off-default: var(--tds-primitive-color-neutral-087);
  --tds-component-toggle-track-color-background-off-hover: var(--tds-primitive-color-neutral-055);
  --tds-component-toggle-track-color-background-off-active: var(--tds-primitive-color-neutral-031);
  --tds-component-toggle-handle-color-background-default: var(--tds-primitive-color-neutral-1000);
  --tds-component-segmented-control-option-color-background-hover: var(--tds-primitive-color-white-200);
  --tds-component-segmented-control-option-color-background-selected: var(--tds-primitive-color-white-300);
  --tds-component-link-color-default: var(--tds-primitive-color-blue-088);
  --tds-component-link-color-hover: var(--tds-primitive-color-blue-051);
  --tds-component-link-color-active: var(--tds-primitive-color-blue-027);
  --tds-component-link-color-visited: var(--tds-primitive-color-purple-088);
  --tds-component-datatable-row-color-background-100-default: var(--tds-primitive-color-neutral-846);
  --tds-component-datatable-row-color-background-100-odd: var(--tds-primitive-color-neutral-721);
  --tds-component-datatable-row-color-background-200-default: var(--tds-primitive-color-neutral-721);
  --tds-component-datatable-row-color-background-200-odd: var(--tds-primitive-color-neutral-585);
  --tds-component-datatable-row-color-background-300-default: var(--tds-primitive-color-neutral-585);
  --tds-component-datatable-row-color-background-300-odd: var(--tds-primitive-color-neutral-425);
  --tds-component-datatable-header-color-background-100: var(--tds-primitive-color-neutral-721);
  --tds-component-datatable-header-color-background-200: var(--tds-primitive-color-neutral-585);
  --tds-component-datatable-header-color-background-300: var(--tds-primitive-color-neutral-425);
  --tds-component-datatable-row-color-background-hover: var(--tds-color-background-transparent-hover);
  --tds-component-datatable-row-color-background-selected: var(--tds-color-background-selected);
  --tds-component-datatable-header-color-background-hover: var(--tds-color-background-transparent-hover);
  --tds-component-datatable-header-color-background-selected: var(--tds-color-background-selected);
}