// Accordion button
.accordion-button {
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
  line-height: 1.375rem;

  &:disabled {
    background-color: $bg-grey-disabled;
    color: $grey-disabled;
  }

  &:disabled::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238a8a8a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  &:disabled .additional-text {
    pointer-events: none;
  }
}

// Accordion item
.accordion-item {
  border: none;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, .1);
}
.accordion-item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.accordion-collapse.collapse,
.accordion-collapse.collapsing {
  background-color: $base;
}

// Accordion body
.accordion-body {
  font-size: .875rem;
  font-weight: $font-weight-normal;
  line-height: 1.375rem;
}

//  Accordion button typography
.accordion-button .additional-text {
  margin-bottom: 0;
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: $font-weight-normal;
  line-height: 1.25rem;
  color: $grey-dark;
}
