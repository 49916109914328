.form-group {
  color: $primary-grey;

  .form-placeholder {
    font-size: .75rem;
    font-weight: $font-weight-normal;
    color: $grey-dark;
  }

  &.disabled {
    .form-label,
    .form-placeholder,
    .form-text {
      color: $grey-disabled;
    }

    .form-mandatory {
      color: $grey-disabled;
    }
  }

  [class^="material-icons"] {
    font-size: 1rem;
    vertical-align: bottom;
  }
}
