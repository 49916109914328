// Modal content
.modal-content {
  box-shadow: 1px 4px 10px rgba(0, 0, 0, .1);
}
// Modal footer
.modal-footer {
  gap: 1rem;
}
// Modal title
.modal-title {
  font-size: 1.125rem;
  font-weight: $font-weight-medium;
}
// Modal body
.modal-body {
  font-size: .875rem;
  line-height: 1.125rem;
}
